<template>
  <div :class="`team-trainer-item `+activeClass " @click="onClick">
    <!-- base info -->
    <vs-row vs-w="12" class="base-info">
      <vs-col class="avatar">
        <vs-avatar v-if="user && user.image_url" :src="user.image_url" />
        <vs-avatar v-else :text="user.name" />
      </vs-col>
      <vs-col class="info">
        <div class="name">
          <span class="fullname">{{getUserName(trainer.user_id)}}</span>
          <span class="owner"><br/>{{user.mobile}} </span>
          <span class="owner" v-if="trainer.is_organization_owner"> | 组织所有人</span>
          <span class="clients">
            <br />活动客户{{trainer.active_clients_count}}人
          </span>
          <span class="admin" v-if="trainer.super_admin">| 管理员</span>
        </div>
      </vs-col>
    </vs-row>

    <!-- Compliance rate-->
    <vs-row>
      <vs-col vs-w="6">完成率：</vs-col>
      <vs-col vs-w="2">
        <span :class="`rate-value ` + rateClass(trainerComplianceRates[0])">{{trainerComplianceRates[0]}}<sup>%</sup></span>
      </vs-col>
      <vs-col vs-w="2">
        <span :class="'rate-value ' + rateClass(trainerComplianceRates[1])">{{trainerComplianceRates[1]}}<sup>%</sup></span>
      </vs-col>
      <vs-col vs-w="2">
        <span :class="'rate-value ' + rateClass(trainerComplianceRates[2])">{{trainerComplianceRates[2]}}<sup>%</sup></span>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

import { mapState } from "vuex";

export default {
  mixins: [baseFun],

  props: ["trainer", "selected"],

  data() {
    return {
      mySelected: null,
      user:null,
      trainerComplianceRates: [0, 0, 0]
    };
  },

  computed: {
    activeClass() {
      return this.mySelected ? "active" : "";
    },

    ...mapState([
      "activeTrainer",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "workouts",
      "workout_items",
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "programs",
      "program_assignments"
    ])
  },

  created() {
    // console.log("TeamTrainerItem: id=" + this.trainer.id);

    this.mySelected = this.selected;

    this.getTrainerComplianceRate();

    if (this.trainer) this.user = this.getUser(this.trainer.user_id);
  },

  watch:{
    selected(newVal){
      this.mySelected = newVal;
    },

    trainer(newVal){
      this.user = this.getUser(newVal.user_id);
    }
  },

  methods: {
    onClick() {
      this.$emit("click", this);
    },

    rateClass(rate) {
      return rate >= 80 ? "high" : "low";
    },

    getTrainerComplianceRate() {
      let data = this.trainerComplianceRates;
      let activeClients = this.clients.filter(
        itm => itm.state == "active" && itm.trainer_id == this.trainer.id
      );
      activeClients.forEach(el => {
        data[0] += parseFloat(el.compliance_rate_for_7_days?el.compliance_rate_for_7_days:0);
        data[1] += parseFloat(el.compliance_rate_for_30_days?el.compliance_rate_for_30_days:0);
        data[2] += parseFloat(el.compliance_rate_for_90_days?el.compliance_rate_for_90_days:0);
      });
      if (activeClients.length) {
        data[0] = ((data[0] / activeClients.length)).toFixed(0);
        data[1] = ((data[1] / activeClients.length)).toFixed(0);
        data[2] = ((data[2] / activeClients.length)).toFixed(0);
      }
    }
  }
};
</script>

<style scoped>
.team-trainer-item {
  padding-top: 15px;
  width: 230px;
  cursor: pointer;
  border-radius: 5px;
}
.team-trainer-item.active {
  background: rgba(0, 0, 0, 0.1) !important;
}
.base-info {
  border-bottom: 1px solid #d8dcdf;
}
.avatar {
  width: 45px !important;
}
.info {
  width: calc(100% - 50px) !important;
}
.fullname {
  font-weight: bold;
  font-size: 120%;
}
.owner{
  font-size: 80%;
}
.rate-value{
  font-size: 80%;
}
.rate-value.high {
  color: green;
}
.rate-value.low {
  color: red;
}
</style>
