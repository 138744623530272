
<template>
  <vs-card class="teams">
    <!-- top bar -->
    <vs-row class="top">
      <vs-col vs-w="1" vs-type="flex" vs-justify="center">
        <img :src="activeOrganization.image_url" class="top-org-img" />
      </vs-col>
      <vs-col vs-w="5">
        <div class="top-team-setting">团队设置</div>
        <h2 class="top-team-name">{{activeOrganization.name}}</h2>
        <div
          class="top-team-numbers"
        >教练{{organizationTrainers.length}}人 | 学员{{filteredClients.length}}人</div>
      </vs-col>
      <vs-col v-if="activeTrainer.super_admin" vs-w="6" vs-type="flex" vs-justify="flex-end" vs-align="top">
        <div class="btn-group">
          <vs-button color="primary" type="filled" icon="add" @click="addTrainer">新加教练</vs-button>
          <vs-button type="filled" icon="refresh" @click="loadTeam()">刷新</vs-button>
        </div>
      </vs-col>
    </vs-row>

    <!-- trainer & client list -->
    <vs-row class="list">
      <!-- trainers -->
      <vs-col vs-w="2" class="trainer-list">
        <h3>教练列表</h3>

        <TeamTrainerItem
          ref="trainerItem"
          v-for="(trainer, trIdx) in filteredTrainers"
          :key="trIdx"
          :trainer="trainer"
          :selected="trainer.id==activeTrainer.id"
          @click="onTrainerClick($event)"
        />
      </vs-col>

      <!-- current trainer's clients -->
      <vs-col vs-w="10" class="client-list">
        <!-- filter & actions -->
        <vs-row vs-w="12">
          <vs-col vs-type="flex" vs-w="12" vs-justify="flex-end">
            <div>
              <vs-button color="primary" type="filled" icon="add" @click="addClient">添加学员</vs-button>
            </div>
          </vs-col>
        </vs-row>
        <!--client list-->
        <vs-row ref="clientList" vs-w="12">
          <vs-col vs-type="flex" vs-w="12" vs-justify="flex-start">
            <vs-tabs>
              <vs-tab :label="'活动学员'">
                <vs-table :data="activeClients" search class="con-tab-ejemplo">
                  <template slot="thead">
                    <vs-th>名称</vs-th>
                    <vs-th sort-key="due">最近训练</vs-th>
                    <vs-th>完成率</vs-th>
                    <vs-th>分组</vs-th>
                    <vs-th>状态切换</vs-th>
                    <vs-th>调换教练</vs-th>
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td>
                        <div
                          class="client-list-name flex items-center ml-1"
                          @click="$router.push('/clients/' + tr.client.slug +'/workouts')"
                        >
                          <vs-avatar v-if="tr.user.image_url" :src="tr.user.image_url" />
                          <vs-avatar v-else :text="tr.user.name" />
                          <span>{{tr.user.name}}({{tr.user.mobile}})</span>
                        </div>
                      </vs-td>
                      <vs-td>
                        <span class="client-list-due">{{clientDueDate(tr.client)}}</span>
                      </vs-td>
                      <vs-td class="client-list-rate">
                        <span
                          v-for="(rate, rIdx) in clientComplianceRate(tr.client)"
                          :key="rIdx"
                          :class="{'rate':true, 'high':rate>=80}"
                        >{{rate}}<sup>%</sup></span>
                      </vs-td>
                      <vs-td class="client-list-group">
                        <vs-chip v-for="(g,gIdx) in clientGroups(tr.client)" :key="gIdx">{{g.name}}</vs-chip>
                      </vs-td>
                      <vs-td class="client-list-action">
                        <vs-button color="danger" icon="power_off"  @click="clientStateUpdate(tr.client, false)">去归档</vs-button>
                      </vs-td>
                      <vs-td class="client-list-action">
                        <vs-button color="warning" icon="transform"  @click="transferCoach(tr.client, true)">调换教练</vs-button>
                      </vs-td>
                      <!-- <vs-td class="client-list-action">
                        <vs-dropdown >
                          <a class="a-icon" href="#"> 更多... <vs-icon class="" icon="expand_more"></vs-icon></a>
                          <vs-dropdown-menu>
                            <vs-dropdown-item><vs-icon icon="person" /> <span>修改基础信息</span></vs-dropdown-item>
                            <vs-dropdown-item @click="transferCoach(tr)"><vs-icon icon="transform" /> <span>调换教练</span></vs-dropdown-item>
                            <vs-dropdown-item @click="clientStateUpdate(tr, false)"><vs-icon icon="power_off"/> <span>归档学员</span></vs-dropdown-item>
                          </vs-dropdown-menu>
                        </vs-dropdown>
                      </vs-td> -->
                    </vs-tr>
                  </template>
                </vs-table>
              </vs-tab>
              <vs-tab :label="'归档学员'">
                <vs-table :data="archivedClients" search class="con-tab-ejemplo">
                  <template slot="thead">
                    <vs-th >名称</vs-th>
                    <vs-th sort-key="due">训练日</vs-th>
                    <vs-th>完成率</vs-th>
                    <vs-th>分组</vs-th>
                    <vs-th>状态切换</vs-th>
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td>
                        <div
                          class="client-list-name flex items-center ml-1"
                          @click="$router.push('/clients/' + tr.client.slug +'/workouts')"
                        >
                          <vs-avatar v-if="tr.user.image_url" :src="tr.user.image_url" />
                          <vs-avatar v-else :text="tr.user.name" />
                          <span>{{tr.user.name}}({{tr.user.mobile}})</span>
                        </div>
                      </vs-td>
                      <vs-td>
                        <span class="client-list-due">{{clientDueDate(tr.client)}}</span>
                      </vs-td>
                      <vs-td class="client-list-rate">
                        <span
                          v-for="(rate, rIdx) in clientComplianceRate(tr.client)"
                          :key="rIdx"
                          :class="{'rate':true, 'high':rate>=80}"
                        >
                          {{rate}}
                          <sup>%</sup>
                        </span>
                      </vs-td>
                      <vs-td class="client-list-group">
                        <vs-chip v-for="(g,gIdx) in clientGroups(tr.client)" :key="gIdx">{{g.name}}</vs-chip>
                      </vs-td>
                      <vs-td class="client-list-action">
                        <vs-button color="success" icon="power"  @click="clientStateUpdate(tr.client, true)">去激活</vs-button>
                      </vs-td>
                      <!-- <vs-td class="client-list-action">
                        <vs-button color="danger" icon="transform"  @click="clientStateUpdate(tr, true)">调换教练</vs-button>
                      </vs-td> -->
                        <!-- <vs-dropdown >
                          <a class="a-icon" href="#"> 更多... <vs-icon class="" icon="expand_more"></vs-icon></a>
                          <vs-dropdown-menu>
                            <vs-dropdown-item><vs-icon icon="person" /> <span>修改基础信息</span></vs-dropdown-item>
                            <vs-dropdown-item @click="transferCoach(tr)"><vs-icon icon="transform" /> <span>调换教练</span></vs-dropdown-item>
                            <vs-dropdown-item @click="clientStateUpdate(tr, true)"><vs-icon icon="power" /> <span>激活学员</span></vs-dropdown-item>
                          </vs-dropdown-menu>
                        </vs-dropdown> -->
                      <!-- </vs-td> -->
                    </vs-tr>
                  </template>
                </vs-table>
              </vs-tab>
            </vs-tabs>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <!--popups: new memembers and transfer -->
    <vs-row>
      <NewClient
        :trainer_id="selectedTrainer.id"
        :show="showNewClient"
        @hide="showNewClient=false"
        :organization="this.activeOrganization"
      />
      <NewTrainer :show="showNewTrainer" @hide="showNewTrainer=false" />

      <vs-popup title="调换教练" :active.sync="showTransfer">
        <TeamTransfer :client="selectedClient" @confirm="transferCoachConfirm" />
      </vs-popup>
    </vs-row>
  </vs-card>
</template>

<script>
//sample data
// import * as sample from "@/store/samples/coach/team/team.js";

import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

import TeamTrainerItem from "@/views/components/coach/team/TeamTrainerItem.vue";
import TeamTransfer from "@/views/components/coach/team/TeamTransfer.vue";
import NewClient from "@/views/components/coach/client/NewClient.vue";
import NewTrainer from "@/views/components/coach/client/NewTrainer.vue";

export default {
  mixins: [baseFun],

  data() {
    return {
      selectedTrainer: null,
      selectedClient: null,

      showNewClient: false,
      showNewTrainer: false,
      showTransfer: false
    };
  },

  created() {
    this.$store.commit("coachPageIdx", 4);

    // this.trainers = sample.trainers.sort((a, b) => {
    //   return a.is_organization_owner ? -1 : b.is_organization_owner ? 1 : 0;
    // });
    this.selectedTrainer = this.activeTrainer;
    this.loadTeam();
  },

  computed: {
    owner() {
      return this.getOwner();
    },

    activeClients() {
      let trainer_id = this.selectedTrainer.id;
      let ary = [];
      this.clients.forEach(client => {
        if (client.trainer_id == trainer_id && client.state=='active'){
          let user = this.getUser(client.user_id);
          ary.push({client, user});
        }
      });
      return ary;
    },

    archivedClients() {
      let trainer_id = this.selectedTrainer.id;
      let ary = [];
      this.clients.forEach(client => {
        if (client.trainer_id == trainer_id && client.state!='active'){
          let user = this.getUser(client.user_id);
          ary.push({client, user});
        }
      });
      return ary;
    },

    filteredTrainers(){
      let orgId = this.activeOrganization.id;
      let ary =  this.selectedTrainer?
        (this.activeTrainer.super_admin?this.trainers.filter(item => item.organization_id == orgId):[this.selectedTrainer]):
        (null);
      ary.sort((a,b)=>a.id-b.id);
      return ary;
    },

    organizationTrainers(){
      let orgId = this.activeOrganization.id;
      return this.trainers.filter(item => item.organization_id == orgId);
    },

    filteredClients(){
      let orgId = this.activeOrganization.id;
      let ary = this.clients.filter(itm => {
        if (itm.organization_id == orgId && itm.state=='active'){
          // console.log("filteredClients", itm.id);
          return true;
        }
      });
      return ary;
    },

    ...mapState([
      "activeTrainer",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "workouts",
      "workout_items",
      "activeUser",
      "activeOrganization",
      "programs",
      "program_assignments",
      "group_assignments"
    ])
  },

  methods: {
    loadTeam(){
      if (this.activeOrganization) this.$store.dispatch("getTeam", this.activeOrganization.id);
    },

    clientDueDate(client) {
      if (client.due) {
        let due = new Date(client.due);
        return due.toLocaleDateString("zh-Hans-CN", {
          month: "long",
          day: "numeric"
        });
      } else {
        return '';
      }
    },

    clientGroups(client) {
      let clientGroupIDs = [];
      this.group_assignments.forEach(gsItm => {
        if (gsItm.id == client.group_assignment_ids)
          clientGroupIDs.push(gsItm.group_id);
      });
      let clientGroups = this.groups.filter(gItm =>
        clientGroupIDs.includes(gItm.id)
      );
      return clientGroups;
    },

    onTrainerClick(e) {
      // console.log("trainer clicked");
      // console.log(this.$event);
      // console.log("binded tranier id=" + e.trainer.id);

      //change trainer list items active state
      this.$refs.trainerItem.forEach(itm => (itm.mySelected = false));
      e.mySelected = true;

      //change current trainer
      this.selectedTrainer = e.trainer;
      // console.log("activeTrainer.id=" + this.activeTrainer.id);
      this.$nextTick(()=>this.$forceUpdate());
    },

    addClient() {
      // console.log("add client");
      this.showNewClient = true;
    },

    addTrainer() {
      // console.log("add trainer");
      this.showNewTrainer = true;
    },

    transferCoach(client){
      // console.log("transferCoach", client);
      this.selectedClient = client;
      this.showTransfer = true;
    },

    transferCoachConfirm(payload){
      // console.log(payload);
      this.showTransfer = false;

      // UI version
      // step 1: remove client from orignal trainer
      let oldTrainer = this.getTrainer(payload.old_trainer_id);
      if (oldTrainer){
        oldTrainer = JSON.parse(JSON.stringify(oldTrainer));
        oldTrainer.active_clients_count -= 1;
        this.$store.dispatch("updateTrainer", {trainer:oldTrainer});
      }

      // step 2: add client to new trainer
      let newTrainer = this.getTrainer(payload.new_trainer_id);
      if (newTrainer){
        newTrainer = JSON.parse(JSON.stringify(newTrainer));
        newTrainer.active_clients_count += 1;
        this.$store.dispatch("updateTrainer", {trainer:newTrainer});
      }

      // step 3: set the client's trainer to the new one
      let client = this.getClient(payload.client_id);
      client = JSON.parse(JSON.stringify(client));
      client.trainer_id = payload.new_trainer_id;
      this.$store.dispatch("updateClient", {vm:this, client:client});


      // // API version
      // let orgId = this.activeOrganization.id;
      // this.$store.dispatch("transferTrainer",
      //   {
      //     vm:this,
      //     trainer_id:payload.new_trainer_id,
      //     client_id:payload.client_id,
      //     organization_id: orgId
      //   }
      // );
    },

    clientStateUpdate(client, toActive){
      // console.log("active client", client);
      let newState = toActive?"active":"archived"
      if (client && client.state!=newState){
        let newClient = JSON.parse(JSON.stringify(client));
        newClient.state = newState;
        this.$store.dispatch("updateClient", {vm:this, client:newClient});
      }
    }
  },

  components: {
    NewClient,
    NewTrainer,
    TeamTrainerItem,
    TeamTransfer
  }
};
</script>

<style scoped>
.top {
  border-bottom: 1px solid #d8dcdf;
  padding-bottom: 15px;
}
.top-org-img {
  width: 60px;
  height: 60px;
}
.top-team-setting {
  font-size: 100%;
}

.btn-group button{
  float: left !important;
}

.list {
  padding-top: 15px;
}
.trainer-list {
  min-width: 260px;
}
.client-list {
  max-width: calc(100% - 260px);
}

.client-list-name {
  white-space: nowrap;
}

.rate {
  padding: 0 10px;
  color: red;
  font-size: 80%;
}
.rate.high {
  color: green;
}

.con-tab-ejemplo{
  padding-bottom: 30px;
}
</style>
