
export default {
  methods: {
    // get due date yyyy-MM-dd
    getDue(date) {
      let due = '';
      if (date && date.getFullYear) {
        // console.log(date);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        due = year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
      }
      return due;
    },

    //id = user id
    getUser(id) {
      if (!this.users) return null;
      let filterUser = this.users.find(u => u.id == id);
      return filterUser;
    },

    getUserName(id) {
      let user = this.getUser(id);
      return user ? user.name : "";
    },

    getUserMobile(id) {
      let user = this.getUser(id);
      return user ? user.mobile : "";
    },

    //id = user id
    getUserImageURL(id) {
      let user = this.getUser(id);
      return user ? user.image_url : "";
    },

    // id = trainer id
    getTrainer(id){
      return (id && this.trainers)?this.trainers.find(item => item.id == id):null;
    },

    //id = trainer id
    getTrainerImageURL(id) {
      if (!this.trainers) return "";
      let trainer = this.trainers.find(itm => itm.id == id);
      let url = trainer ? this.getUserImageURL(trainer.user_id) : "";
      return url;
    },

    //id = client id
    getClientImageURL(id) {
      if (!this.clients) return "";
      let client = this.clients.find(itm => itm.id == id);
      let url = client ? this.getUserImageURL(client.user_id) : "";
      return url;
    },

    //id = client id
    getClientUserName(id) {
      let client = this.getClient(id);
      let user = client ? this.getUser(client.user_id) : null;
      return user ? user.name : "";
    },

    //id = client id
    getClientSlug(id) {
      let client = this.getClient(id);
      return client ? client.slug : '';
    },

    //id = client id
    getClientType(id) {
      let client = this.getClient(id);
      return client ? client.client_type : "";
    },

    //id = trainer id
    getTrainerUser(id) {
      if (!this.trainers) return "";
      let trainer = this.trainers.find(itm => itm.id == id);
      return trainer ? this.getUser(trainer.user_id) : null;
    },

    //id = trainer id
    getTrainerUserName(id) {
      let user = this.getTrainerUser(id);
      return user ? user.name : "";
    },

    //get the first administrator
    getAdministrator() {
      let trainers = this.trainers.filter(itm => itm.super_admin);
      let trainer = trainers ? trainers[0] : null;
      return trainer;
    },

    //get the administrators
    getAdministrators() {
      let trainers = this.trainers.filter(itm => itm.super_admin);
      return trainers;
    },

    //get the first administrator
    getOwner() {
      let trainers = this.trainers.filter(itm => itm.is_organization_owner);
      let trainer = trainers ? trainers[0] : null;
      return trainer;
    },

    //get client by the client id
    getClient(id) {
      if (!id) return null;

      let clients = this.clients ? this.clients.filter(itm => itm.id == id) : []
      let client = clients && clients.length ? clients[0] : null;

      return client;
    },

    getClientBySlug(slug) {
      if (!(slug && this.clients)) return null;
      let filterClients = this.clients.filter(u => u.slug == slug);
      return filterClients.length ? filterClients[0] : null;
    },

    getWorkout(id){
      return id && this.workouts? this.workouts.find(item=>item.id==id):null;
    },

    getProgram(id) {
      if (!id) return null;

      let program = this.programs ? this.programs.find(item => item.id == id) : null;

      return program;
    },

    getOrganization(id) {
      let org = (this.organizations && id) ? this.organizations.find(item => item.id == id) : null;
      return org;
    },

    getOrganizationName(id) {
      let org = (this.organizations && id) ? this.organizations.find(item => item.id == id) : null;
      return org ? org.name : "";
    },

    trainerClients(trainer) {
      let clients = this.clients.find(itm => itm.trainer_id = trainer.id)
      return clients
    },

    clientGroups(client) {
      let clientGroupIDs = [];
      if (this.group_assignments) this.group_assignments.forEach(gsItm => {
        if (gsItm.id == client.group_assignment_ids)
          clientGroupIDs.push(gsItm.group_id);
      });
      let clientGroups = this.groups.filter(gItm =>
        clientGroupIDs.includes(gItm.id)
      );
      return clientGroups;
    },

    //client ComplianceRate
    clientComplianceRate(client) {
      let data = [0, 0, 0];
      let d7 = client.compliance_rate_for_7_days
      let d30 = client.compliance_rate_for_30_days
      let d90 = client.compliance_rate_for_90_days

      data[0] = (parseFloat(d7 ? d7 : 0)).toFixed(0);
      data[1] = (parseFloat(d30 ? d30 : 0)).toFixed(0);
      data[2] = (parseFloat(d90 ? d90 : 0)).toFixed(0);
      return data;
    },

    complianceRate(rate) {
      //return rate ? parseInt(rate.toString().substr(2, 2)) : 0;
      return rate ? parseFloat(rate).toFixed(0) : 0;
    },

    complianceRateName(rate) {
      return this.complianceRate(rate) >= 80 ? "complate" : "incomplate";
    },

    localDate(dtDate) {
      if (!dtDate) return "";

      let dt = new Date(dtDate);
      return dt.toLocaleString('zh-Hans-CN', { month: 'short', day: 'numeric' }) + ", " + dt.toLocaleString('zh-Hans-CN', { weekday: 'long' });
    },

    timePast(dtDate) {
      // let now = Date.parse(new Date());
      // let past = Date.parse(new Date(dtDate));
      // let pasted = new Date(now - past);
      // let slt = {
      //   "years": pasted.getFullYear() - 1970,
      //   "months": pasted.getMonth(),
      //   "days": pasted.getDate(),
      //   "hours": pasted.getHours(),
      //   "minutes": pasted.getMinutes(),
      //   "seconds": pasted.getSeconds()
      // };
      const aYear = 1000 * 60 * 60 * 24 * 365;
      const aMonth = 1000 * 60 * 60 * 24 * 30;
      const aDay = 1000 * 60 * 60 * 24;
      const aHour = 1000 * 60 * 60;
      const aMinute = 1000 * 60;
      const aSecond = 1000;

      let now = new Date();
      let past = new Date(dtDate);
      let pasted = now - past;
      let left = pasted;
      let years = Math.trunc(left / aYear);
      left = left % aYear;
      let months = Math.trunc(left / aMonth);
      left = left % aMonth;
      let days = Math.trunc(left / aDay);
      left = left % aDay;
      let hours = Math.trunc(left / aHour);
      left = left % aHour;
      let minutes = Math.trunc(left / aMinute);
      left = left % aMinute;
      let seconds = Math.trunc(left / aSecond);


      let sRtn = "";
      if (years > 1) sRtn += years + "年";
      else if (months > 1) sRtn += months + "月";
      else if (days > 1) sRtn += days + "天";
      else if (hours > 1) sRtn += hours + "小时";
      else if (minutes > 1) sRtn += minutes + "分钟";
      else if (seconds) sRtn += seconds + "秒";
      return sRtn;
    },

    workout_item_list(workout_id) {
      return this.workout_items ? this.workout_items.filter(itm => itm.workout_id == workout_id) : [];
    },

    workoutItemVideo(workoutItem) {
      let video = null
      if (workoutItem && (workoutItem.exercise_id || workoutItem.attachments.length)) {
        if (workoutItem.exercise_id) {
          let exercise = this.getExercises(workoutItem.exercise_id)
          if (exercise && exercise.attachments && exercise.attachments.length) {
            video = exercise.attachments[0]
          }
        } else {
          video = workoutItem.attachments[0]
        }
      }
      return video
    },

    getComment(id) {
      if (!(id && this.comments)) return null;
      let comments = this.comments.filter(itm => itm.id == id);
      return comments.length ? comments[0] : null;
    },

    getComments(ids) {
      if (!(ids && this.comments)) return [];
      let comments = this.comments.filter(itm => ids.includes(itm.id));
      return comments;
    },

    stateText(val) {
      let Name = {
        completed: "完成",
        missed: "错过",
        pending: "未开始"
      };
      return Name[val];
    },

    numberToChar(num, startNum) {
      startNum = startNum ? startNum : 0;
      let ACode = "A".charCodeAt(0)
      return String.fromCharCode(ACode + num + startNum)
    },

    // 根据program.highest_position 获取计划的总天数（整周）
    programPositionToDays(position) {
      return this.programPositionToWeeks(position) * 7;
    },

    // 根据program.highest_position 获取计划的总周数
    programPositionToWeeks(position) {
      let num = Math.trunc((position + 6) / 7);
      return num ? num : 1;
    },

    /**
     * new objexts
     */
    getNewOrganization() {
      return {
        "id": 0,
        "name": null,
        "image_url": null,
        "owner_id": 0,
        "active_clients_count": 0
      }
    },

    getNewExercise(trainer_id, organization_id) {
      return {
        id: 0,
        default: false,
        exercise_name: null,
        description: null,
        url: null,
        attachments: [],
        trainer_id: trainer_id,
        organization_id: organization_id
      }
    },

    getNewWarmup(trainer_id, organization_id) {
      return {
        "id": null,
        "description": null,
        "name": null,
        "trainer_id": trainer_id,
        "organization_id": organization_id,
        "selected_exercises": []
      }
    },

    getNewCooldown(trainer_id, organization_id) {
      return {
        "id": 0,
        "description": "",
        "name": "",
        "trainer_id": trainer_id,
        "organization_id": organization_id,
        "selected_exercises": []
      }
    },

    getNewWorkout(client_id, day, is_rest, position, program_id, program_name) {
      // if (!day) day = new Date()
      let dayValue = day ? (day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate()) : null;
      return {
        "id": 0,
        "due": dayValue,
        "short_description": null,
        "created_at": null,
        "updated_at": null,
        "title": null,
        "state": "pending",
        "rest_day": is_rest,
        "rest_day_instructions": "",
        "warmup": null,
        "warmup_selected_exercises": [],
        "cooldown_selected_exercises": [],
        "cooldown": null,
        "position": position ? position : 1,
        "order": 1,
        "uuid": null,
        "program_name": program_name ? program_name : null,
        "client_id": client_id,
        "comment_ids": [],
        "program_id": program_id ? program_id : null,
        "workout_item_ids": [],
        "workout_items": []
      }
    },

    getNewWorkoutItem(position) {
      return {
        "name": "",
        "info": "",
        "result": "",
        "created_at": null,
        "linked": false,
        "position": position,
        "is_circuit": false,
        "state": "pending",
        "selected_exercises": [],
        "attachments": [],
        "workout_id": null,
        "assessment_id": null,
        "exercise_id": null
      }
    },

    getNewAttachment(name, size, type, url) {
      return {
        "name": name,
        "attachment_url": url,
        "type": type,
        "size": size
      }
    },

    getNewComment(workout_id, user_id) {
      return {
        "id": 0,
        "body": "",
        "workout_id": workout_id,
        "attachments": [],
        "user_id": user_id
      }
    },

    getNewProgram(orgId, trainerId) {
      return {
        "id": null,
        "name": "",
        "description": "",
        "shared": true,
        "highest_position": 1,
        "length": 1,
        "organization_id": orgId,
        "trainer_id": trainerId
      }
    },

    getClientWorkout(client, day) {

      if (!client) return null;

      let dueWorkouts = this.workouts.filter(wk => {
        let due = new Date(wk.due);
        return (
          wk.client_id == client.id &&
          due.getDate() == day.getDate() &&
          due.getMonth() == day.getMonth() &&
          due.getFullYear() == day.getFullYear()
        );
      });
      return dueWorkouts && dueWorkouts.length ? dueWorkouts[0] : null;
    },

    getWarmupByName(name) {
      let lst = [];
      if (this.warmups) lst = this.warmups.filter(itm => itm.name == name)
      return lst.length ? lst[0] : null
    },

    getCooldownByName(name) {
      let lst = [];
      if (this.cooldowns) lst = this.cooldowns.filter(itm => itm.name == name)
      return lst.length ? lst[0] : null
    },

    getExerciseByName(name) {
      let lst = [];
      if (this.exercises) lst = this.exercises.filter(itm => itm.exercise_name == name)
      return lst.length ? lst[0] : null
    },

    /**
     * exercises
     */
    getExercises(id) {
      let ortn = null
      let exercises = this.exercises ? this.exercises.filter(itm => itm.id == id) : null
      if (exercises && exercises.length) ortn = exercises[0]

      return ortn
    },

    /**
     * common array operations
     */
    getArrayItem(arrayName, id) {
      if (!arrayName || !id) throw arguments.callee.name + " -> wrong prameter";

      let ary = this[arrayName];
      if (!ary) throw arguments.callee.name + " -> the array " + arrayName + " does not exists.";
      let rlt = ary.filter(itm => itm.id == id);
      return rlt.length > 0 ? rlt[0] : null;
    },

    addReplaceArrayItem(arrayName, item) {
      if (!arrayName || !item) throw arguments.callee.name + " -> wrong prameter";

      let oldItm = this.getArrayItem(arrayName, item.id);
      if (oldItm) {
        let idx = this[arrayName].indexOf(oldItm);
        this[arrayName][idx] = item;
      } else {
        this[arrayName].push(item);
      }
    },

    addReplaceArrayItems(arrayName, items) {
      if (!arrayName || !items) throw arguments.callee.name + " -> wrong prameter";

      let ary = this[arrayName];
      if (!ary) throw arguments.callee.name + " -> the array " + arrayName + " does not exists.";

      items.forEach(item => this.addReplaceArrayItem(arrayName, item));
    },

    removeArrayItem(arrayName, item) {
      if (!arrayName || !item) throw arguments.callee.name + " -> wrong prameter";

      let idx = this[arrayName].indexOf(item);
      if (idx >= 0) {
        this[arrayName].splice(idx, 1);
      }
    }
  }
}
