<template>
  <div class="container">
    <vs-row>
      <vs-col vs-w="12">
        <span>将学员 {{clientUser?clientUser.name+'('+clientUser.mobile+')':''}} 的教练指派为：</span>
      </vs-col>
      <vs-col vs-w="12">
        <select v-model="selectedCoach" required class="users">
          <option disabled value="">选择一个教练</option>
          <option v-for="(item, itemIdx) in coachUsers" :value="item.trainer.id" :key="itemIdx">{{item.user.name}}</option>
        </select>
      </vs-col>
      <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" class="buttons">
        <vs-button color="primary" @click="confirm">确定</vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],

  props: ["client"],

  data(){
    return {
      selectedCoach:'',
      clientUser:null
    }
  },

  watch: {
    client(newVal){
      this.clientUser = newVal?this.getUser(newVal.user_id):null;
    }
  },

  created() {
    this.clientUser = this.client?this.getUser(this.client.user_id):null;
  },

  computed:{
    // clientUser(){
    //   return this.client?this.getUser(this.client.user_id):null;
    // },

    coachUsers(){
      let trainers = [];
      let me = this;
      let trainerId = this.client?this.client.trainer_id:0;
      let orgId = this.activeOrganization?this.activeOrganization.id:0;

      this.trainers.forEach(trainer => {
        if (trainer.id == trainerId || trainer.organization_id != orgId) return;

        let user = me.getUser(trainer.user_id);
        if (user && user.id!=trainerId) trainers.push({trainer, user});
      });
      return trainers;
    },

    ...mapState([
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "workouts",
      "workout_items",
      "programs",
      "program_assignments",
      "group_assignments"
    ])
  },

  methods: {
    confirm(){
      if (!this.selectedCoach) return;

      this.$emit("confirm",
        {
          old_trainer_id:this.client.trainer_id,
          new_trainer_id:this.selectedCoach,
          client_id:this.client.id
        }
      );
    }
  }
}
</script>

<style scoped>
.container {
  min-height: 100px;
}

select.users {
  width: 100%;
  padding: 5px 0;
  font-family: "Microsoft YaHei", "微软雅黑", SimSun, "宋体", Heiti, "黑体",
    sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  margin: 5px 0;
}
select option {
  padding: 5px;
}

.buttons{
  margin: 15px 0;
}
</style>
