<template>
  <vs-popup title="添加新学员" :active.sync="showBox">
    <div v-if="!organization" class="vx-row mb-6">
      <div class="vx-col w-full">
        <select v-model="selectedOrg" required class="orgs">
          <option disabled value="" selected>选择一个组织</option>
          <option v-for="(org, orgIdx) in organizations" :value="org.id" :key="orgIdx">{{org.name}}</option>
        </select>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-user"
          label-placeholder="姓名"
          v-model="user.name"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-smartphone"
          label-placeholder="移动电话"
          v-model="user.mobile"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-lock"
          type="password"
          label-placeholder="密码"
          v-model="user.password"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="saveClient">保存</vs-button>
        <vs-button
          color="warning"
          type="border"
          class="mb-2"
          @click="/*user.mobile=user.name=''; user.type='in-person'; */close()"
        >取消</vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";
import { mapState } from "vuex";

export default {
  mixins: [baseFun],

  props: ["trainer_id", "show", "active", "organization"],

  data() {
    return {
      showBox: this.show,
      selectedOrg:this.organization?this.organization.id:'',

      client: {
        organization_id: this.organization?this.organization.id:0,
        trainer_id: 0,
        client_type: "in-person",
        slug:null,
      },

      user: this.newData()
    };
  },

  computed: {
    ...mapState(["activeTrainer", "activeOrganization", "organizations"])
  },

  created() {
    this.client.trainer_id = this.trainer_id;
    this.client.organization_id = this.organization ? this.organization.id : 0;
    this.client.slug = this.uuid()
  },

  watch: {
    show: function(newVal, oldVal) {
      if (newVal != oldVal) this.showBox = newVal
      if (newVal) this.client.slug = this.uuid()
    },
    showBox: function(newVal) {
      if (!newVal) {
        this.$emit("hide");
      }
    },
    trainer_id: function(newVal) {
      this.client.trainer_id = newVal;
    }
  },

  methods: {
    saveClient() {
      if (this.user.mobile && this.user.name) {
        this.$store.dispatch("createClient", {
          user: this.user,
          client: this.client,
          active: this.active,
          vm: this
        });

        this.close();
      }
    },

    close() {
      this.user = this.newData();
      this.showBox=false;
      this.$emit("hide");
    },

    newData(){
      return {
        name: null,
        mobile: null,
        password: null,
        organization_id: this.organization ? this.organization.id : 0
      }
    },

    uuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        let r = (Math.random() * 16) | 0
        let v = c == "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
  }
};
</script>

<style>
.vs-input--placeholder {
  font-size: 1rem;
}
select.orgs {
    width: 100%;
    padding: 5px 0;
    font-family: "Microsoft YaHei", "微软雅黑", SimSun, "宋体", Heiti, "黑体", sans-serif;
    font-size: 1rem;
    border-radius: 5px;
    margin: 5px 0;
}
</style>
