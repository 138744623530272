<template>
  <vs-popup title="添加新教练" :active.sync="showBox">
    <div class="vx-row mb-6">
      <!-- <vs-alert active="true" :color="" >{{}}</vs-alert> -->
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-user"
          label-placeholder="姓名"
          v-model="user.name"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-smartphone"
          label-placeholder="移动电话"
          v-model="user.mobile"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-lock"
          type="password"
          label-placeholder="密码"
          v-model="user.password"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="saveTrainer">保存</vs-button>
        <vs-button
          color="warning"
          type="border"
          class="mb-2"
          @click="/*user.mobile=user.name=''; user.type='in-person'; */showBox=false;"
        >取消</vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";
import { mapState } from "vuex";

export default {
  mixins: [baseFun],

  props: ["show"],

  data() {
    return {
      showBox: this.show,

      trainer: {
        organization_id: 0,
        can_add_clients: true,
        is_organization_owner: false,
        super_admin: false,
        max_clients: 5
      },

      user: {
        name: null,
        mobile: null,
        password: null
      }
    };
  },

  computed: {
    ...mapState(["activeTrainer", "activeOrganization"])
  },

  created() {
    this.trainer.organization_id = this.activeOrganization.id;
  },

  watch: {
    show: function(newVal, oldVal) {
      if (newVal != oldVal) this.showBox = newVal;
    },
    showBox: function(newVal) {
      if (!newVal) {
        this.$emit("hide");
      }
    }
  },

  methods: {
    saveTrainer() {
      if (this.user.mobile && this.user.name) {
        this.$store.dispatch("createTrainer", {
          user: this.user,
          trainer: this.trainer,
          vm: this
        });
        this.$emit("hide");
      }
    },

    canel() {
      this.$emit("hide");
    }
  }
};
</script>

<style>
.vs-input--placeholder {
  font-size: 1rem;
}
</style>
